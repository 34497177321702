<template>
  <LargeLogoContainer>
    <WhiteFrame>
      <v-row>
        <v-col class="flex-grow-0 flex-shrink-1 text-no-wrap align-end d-flex text-right pr-0">
          <h1 class="text-h5">Welkom bij</h1>
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-0 pl-0">
          <v-img max-width="350px" src="@/assets/Logo_blauw.png" alt="Buildbase Logo" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          {{ topHeaderText }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <PromiseContainer :promises.sync="promises">
            <template v-slot:default>
              <v-stepper v-model="currentStep" flat class="buildbase-stepper pa-0" alt-labels>
                <v-stepper-header v-if="displaySteppers" class="elevation-0">
                  <v-stepper-step :complete="paymentAccountFormCompleted" step="1" :class="{ disabled: !enableAccountStep}">Account</v-stepper-step>
                  <v-divider />
                  <v-stepper-step :complete="organizationFormCompleted" step="2" :class="{ disabled: !enableOrganizationStep}">Organisatie</v-stepper-step>
                  <v-divider />
                  <v-stepper-step :complete="configurationFormCompleted" step="3" :class="{ disabled: !enableConfigurationStep}">Configuratie</v-stepper-step>
                  <v-divider />
                  <v-stepper-step :complete="billingFormCompleted" step="4" :class="{ disabled: !enableBillingStep}">Facturatie</v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content v-if="enableAccountStep" step="1" class="pa-0" :class="{'mt-5': !displaySteppers}">
                    <PaymentAccountForm v-model="accountForm" :completed.sync="paymentAccountFormCompleted"
                      :onNext="accountNextStep"
                      :next-button-text="accountNextButtonText"
                      :display-account-type-radio-btn="isTrialView" :traceFunction="() => createPaymentFlowTrace('Account', '1')" />
                  </v-stepper-content>
                  <v-stepper-content v-if="enableOrganizationStep" step="2">
                    <PaymentOrganizationForm v-model="organizationForm" :completed.sync="organizationFormCompleted"
                      :onPrev="organizationPrevStep" :onNext="requiredNextStep" :traceFunction="() => createPaymentFlowTrace('Organisatie', '2')"/>
                  </v-stepper-content>
                  <v-stepper-content v-if="enableConfigurationStep" step="3">
                    <PaymentConfigurationForm v-model="configurationForm" :completed.sync="configurationFormCompleted"
                    :organization-form="organizationForm"
                      :onPrev="prevStep" :onNext="requiredNextStep" :traceFunction="() => createPaymentFlowTrace('Configuratie', '3')"/>
                  </v-stepper-content>
                  <v-stepper-content v-if="enableBillingStep" step="4">
                    <PaymentBillingForm v-model="billingForm" :completed.sync="billingFormCompleted" :onPrev="prevStep"
                      :onNext="completeStepper" next-button-text="Naar betaling" :organization-form="organizationForm"
                      :account-form="accountForm" :traceFunction="() => createPaymentFlowTrace('Facturatie', '4')"/>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </template>
          </PromiseContainer>
        </v-col>
      </v-row>
    </WhiteFrame>
  </LargeLogoContainer>
</template>

<script>
import LargeLogoContainer from '../../components/shared/LargeLogoContainer.vue';
import PaymentAccountForm from '../../components/payment/PaymentAccountForm.vue';
import WhiteFrame from '../../components/shared/WhiteFrame.vue';
import PaymentOrganizationForm from '../../components/payment/PaymentOrganizationForm.vue';
import PaymentConfigurationForm from '../../components/payment/PaymentConfigurationForm.vue';
import PaymentBillingForm from '../../components/payment/PaymentBillingForm.vue';
import publicIp from 'public-ip';
import {
  conversionCompletedEvent,
  conversionFailedEvent
} from "@/shared/utils/gtagUtils";
import routeNames from "@/router/RouteNames";
import {authenticatedUser} from "@/shared/utils/authenticationUtils";
import PromiseContainer from "@/components/shared/PromiseContainer";
import {traceError, createTrace} from "@/tracing";
import {event} from "vue-gtag";

export default {
  name: "PaymentView",
  components: {
    LargeLogoContainer,
    PaymentAccountForm,
    WhiteFrame,
    PaymentOrganizationForm,
    PaymentConfigurationForm,
    PaymentBillingForm,
    PromiseContainer
  },
  created() {
    publicIp.v4()
      .then((result) => this.ipAddress = result)
      .catch(() => this.ipAddress = "192.168.0.1");
    this.currentStep = this.startStep

    if (this.$route.name == routeNames.TRIAL_TO_PAID) {
      this.promises.push([
        this.$store.dispatch("organizationModule/fetchOrganization", authenticatedUser().organizationId)
          .then(() => { 
            this.existingOrganization = this.$store.state.organizationModule.organization
            this.autofillFormsWithOrganization(this.existingOrganization)
          }),
        this.$store.dispatch('usersModule/fetchJobTitle', authenticatedUser().id)
          .then((result) => this.organizationForm.statute = result.jobTitle)
        ])
    }
  },
  data() {
    return {
      paymentAccountFormCompleted: false,
      accountForm: {
        accountType: this.isTrialView? 'trial' : 'subscription',
        organizationName: null,
        firstName: null,
        lastName: null,
        email: null,
        password: null,

      },
      organizationFormCompleted: false,
      organizationForm: {
        statute: null,
        street: null,
        number: null,
        bus: null,
        postalCode: null,
        city: null,
        countryCode: null,
        vatNumber: null
      },
      configurationFormCompleted: false,
      configurationForm: {
        subscriptionType: 'period',
        packets: null,
        seats: null,
        period: null,
        expectedPrice: null
      },
      billingFormCompleted: false,
      billingForm: {
        paymentMethod: null
      },
      currentStep: null,
      ipAddress: null,
      processingRegistrationRequest: false,
      promises: [],
      existingOrganization: null
    }
  },
  computed: {
    isTrialView() {
      return this.$route.name !== routeNames.TRIAL
    },
    displaySteppers() {
      switch (this.$route.name) {
        case routeNames.TRIAL:
          return false
        case routeNames.PAYMENT:
        case routeNames.TRIAL_TO_PAID:
          return true
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in displaySteppers`))
          return true
      }
    },
    enableAccountStep() {
      return this.$route.name !== routeNames.TRIAL_TO_PAID
    },
    enableOrganizationStep() {
      switch (this.accountForm.accountType) {
        case "subscription":
          return true
        case "trial":
          return false
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in enableOrganizationStep`))
          return true
      }
    },
    enableConfigurationStep() {
      switch (this.accountForm.accountType) {
        case "subscription":
          return true
        case "trial":
          return false
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in enableConfigurationStep`))
          return true
      }
    },
    enableBillingStep() {
      switch (this.accountForm.accountType) {
        case "subscription":
          return true
        case "trial":
          return false
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in enableBillingStep`))
          return true
      }
    },
    accountNextStep() {
      switch (this.accountForm.accountType) {
        case "subscription":
          return this.requiredNextStep
        case "trial":
          return this.completeStepper
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in accountNextStep`))
          return this.requiredNextStep
      }
    },
    accountNextButtonText() {
      switch (this.accountForm.accountType) {
        case "subscription":
          return "Volgende"
        case "trial":
          return "Start 15 dagen gratis"
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in accountNextButtonText`))
          return "Volgende"
      }
    },
    startStep() {
      switch (this.$route.name) {
        case routeNames.TRIAL_TO_PAID:
          return 2
        case routeNames.PAYMENT:
        case routeNames.TRIAL:
          return 1
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in startStep`))
          return 1
      }
    },
    organizationPrevStep() {
      switch (this.$route.name) {
        case routeNames.PAYMENT:
          return this.prevStep
        case routeNames.TRIAL:
          return this.prevStep
        case routeNames.TRIAL_TO_PAID:
          return null
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in organizationPrevStep`))
          return this.prevStep
      }
    },
    topHeaderText() {
      switch (this.accountForm.accountType) {
        case "subscription":
          return "Fijn dat je interesse hebt in het aanschaffen van onze ERP-modules voor de bouwsector. Vul onderstaand je gegevens in om de registratie te voltooien."
        case "trial":
          return "Fijn dat je interesse hebt in een gratis trial van onze ERP-modules voor de bouwsector. Ontdek hoe je als bouwbedrijf je administratie vereenvoudigt met Buildbase gaande van projectbeheer tot personeelsadministratie. Vul onderstaand je gegevens in en probeer onze applicatie gratis gedurende 30 dagen!"
        default:
          traceError(new Error(`No behaviour defined for ${this.$route.name} in topHeaderText`))
          return "Fijn dat je interesse hebt in het aanschaffen van onze ERP-modules voor de bouwsector. Vul onderstaand je gegevens in om de registratie te voltooien."
      }
    },
  },
  methods: {
    createPaymentFlowTrace(name, stepIndex) {
      const nameWithAccountType = this.accountForm.accountType + "_step" + stepIndex + "_" + name
      event(nameWithAccountType)
      createTrace("buildbase-vue-payment", nameWithAccountType, {})
    },
    appendSuccesReturnUrlParams(url) {
      return url + 
        "&price=" + this.configurationForm.expectedPrice +
        "&organizationId=" + this.$store.getters["authModule/getOrganizationId"] +
        "&source=newOrg" +
        "&paymentMethod=" + this.billingForm.paymentMethod +
        "&amountOfMonths=" + (this.configurationForm.subscriptionType == "period" ? this.configurationForm.period : '1')
    },
    autofillFormsWithOrganization(organization) {
      this.accountForm.organizationName = organization.name
      this.accountForm.vatNumber = organization.vatNumber
      this.organizationForm.street = organization.address.street
      this.organizationForm.number = organization.address.number
      this.organizationForm.bus = organization.address.bus
      this.organizationForm.postalCode = organization.address.postalCode
      this.organizationForm.city = organization.address.city
      this.organizationForm.countryCode = organization.address.countryCode
    },
    authenticatedUser,
    completeStepper(completed) {
      if (completed) {
        if (!this.processingRegistrationRequest) {
          this.processingRegistrationRequest = true;
          if (this.$route.name === routeNames.TRIAL_TO_PAID){
            this.completeTrialToPaid()
          } else {
            switch (this.accountForm.accountType) {
              case "subscription":
                this.completeRegistration()
                break;
              case "trial":
                this.completeTrial()
                break;
              default:
                traceError(new Error(`No behaviour defined for ${this.$route.name} in completeStepper`))
                break;
            }
          }
        }
      }
    },
    requiredNextStep(completed) {
      if (completed) {
        this.nextStep()
      }
    },
    nextStep() {
      this.currentStep++
    },
    prevStep() {
      this.currentStep--
    },
    getRegistrationRequest() {
      return {
        adminFirstName: this.accountForm.firstName,
        adminLastName: this.accountForm.lastName,
        adminEmail: this.accountForm.email.toLowerCase(),
        adminPhoneNumber: this.accountForm.phoneNumber,
        adminPassword: this.accountForm.password,
        ipAddress: this.ipAddress,
        expectedPrice: this.configurationForm.expectedPrice,
        organization: this.getOrganizationFromForms(),
        maxAmountOfUsers: this.configurationForm.seats,
        paymentMethod: this.billingForm.paymentMethod,
        priceByElement: Object.fromEntries(new Map(this.configurationForm.selectedSubscriptionElements)),
        amountOfMonths: this.configurationForm.subscriptionType == "period" ? this.configurationForm.period : '1',
        successReturnUrl: this.appendSuccesReturnUrlParams(process.env.VUE_APP_PAYMENT_REGISTER_URL_SUCCESS),
        cancelReturnUrl: process.env.VUE_APP_PAYMENT_REGISTER_URL_CANCEL,
        failureReturnUrl: process.env.VUE_APP_PAYMENT_REGISTER_URL_FAILURE,
        failureButtonUrl: process.env.VUE_APP_PAYMENT_SEATS_URL
      }
    },
    getTrialRequest() {
      return {        
        email: this.accountForm.email.toLowerCase(),
        firstName: this.accountForm.firstName,
        lastName: this.accountForm.lastName,
        phoneNumber: this.accountForm.phoneNumber,
        password: this.accountForm.password,

        organizationName: this.accountForm.organizationName,
        vatNumber: this.accountForm.vatNumber,

        failureButtonUrl: process.env.VUE_APP_PAYMENT_SEATS_URL
      }
    },
    getTrialToPaidRequest() {
      return {
        ipAddress: this.ipAddress,
        expectedPrice: this.configurationForm.expectedPrice,
        maxAmountOfUsers: this.configurationForm.seats,
        paymentMethod: this.billingForm.paymentMethod,
        priceByElement: Object.fromEntries(new Map(this.configurationForm.selectedSubscriptionElements)),
        amountOfMonths: this.configurationForm.subscriptionType == "period" ? this.configurationForm.period : '1',
        successReturnUrl: this.appendSuccesReturnUrlParams(process.env.VUE_APP_PAYMENT_TRIAL_TO_PAID_URL_SUCCESS),
        cancelReturnUrl: process.env.VUE_APP_PAYMENT_TRIAL_TO_PAID_URL_FAILURE,
        failureReturnUrl: process.env.VUE_APP_PAYMENT_TRIAL_TO_PAID_URL_CANCEL,
        organizationAddress: this.getOrganizationFromForms()?.address,
      }
    },
    completeRegistration() {
      const registrationRequest = this.getRegistrationRequest()
      this.promises.push(
        this.$store.dispatch("authModule/initiatePayment_withOrganizationAndAdminUser", registrationRequest)
        .then((result) => {
          conversionCompletedEvent(this.accountForm.accountType)
          window.location.href = result
        })
        .catch(() => conversionFailedEvent(this.accountForm.accountType))
        .finally(() => this.processingRegistrationRequest = false)
      )
    },
    completeTrial() {
      const trialRequest = this.getTrialRequest()
      this.promises.push(
        this.$store.dispatch("authModule/registerTrial", trialRequest)
          .then(
            // on success this function is called
            () => {
              conversionCompletedEvent(this.accountForm.accountType)
              this.$router.push({ name: routeNames.ROOT })
            })
          // on failure this function is called
          .catch(() => conversionFailedEvent(this.accountForm.accountType))
          .finally(() => this.processingRegistrationRequest = false)
      )
    },
    completeTrialToPaid() {
      const trialToPaidRequest = this.getTrialToPaidRequest()
      this.promises.push(
        this.$store.dispatch('paymentModule/extendTrialToPaid', trialToPaidRequest)
          .then((result) => {
            conversionCompletedEvent(this.accountForm.accountType)
            window.location.href = result
          })
          .catch(() => conversionFailedEvent(this.accountForm.accountType))
          .finally(() => this.processingRegistrationRequest = false)
      )
    },
    getOrganizationFromForms() {
      return {
          name: this.accountForm.organizationName,
          vatNumber: this.accountForm.vatNumber,
          seats: this.configurationForm.seats,
          address: {
            street: this.organizationForm.street,
            number: this.organizationForm.number,
            bus: this.organizationForm.bus,
            city: this.organizationForm.city,
            postalCode: this.organizationForm.postalCode,
            countryCode: this.organizationForm.countryCode,
          },
        }
    }
  }
}
</script>
