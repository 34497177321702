<template>
    <StepperForm v-model="billingForm" v-bind="$attrs" v-on="$listeners">
        <v-row>
            <v-col cols="12" lg="7">
                <v-row>
                    <v-col>
                        <h2 class="mb-2">Facturatie</h2>
                    </v-col>
                </v-row>
                <v-row align="baseline" dense>
                    <v-col cols="12" class="text-overline">
                        <label>Facturatie gegevens:</label>
                        <ul class="list-style-none ml-5 line-height-normal">
                            <li class="notranslate">{{ accountForm.organizationName }}</li>
                            <li class="notranslate">{{ organizationForm.street }} {{ organizationForm.number }} {{ organizationForm.bus }}</li>
                            <li>{{ organizationForm.postalCode }} {{ organizationForm.city }}</li>
                            <li>BTW: {{ accountForm.vatNumber }}</li>
                        </ul>
                    </v-col>
                    <v-col cols="12">
                        <PaymentSelection v-model="billingForm.paymentMethod"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </StepperForm>
</template>

<script>
import StepperForm from '../shared/StepperForm.vue';
import PaymentSelection from "@/components/payment/PaymentSelection";
export default {
    name: "PaymentBillingForm",
    model: {
        prop: 'billingForm',
        event: 'update:billingForm'
    },
    props: {
        completed: Boolean,
        organizationForm: Object,
        accountForm: Object,
        billingForm: Object
    },
    components: {
        StepperForm,
        PaymentSelection
    },
    emits: ['update:completed'],
}
</script>